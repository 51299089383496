import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SideBarService } from './../../services/side-bar.service';
import { RoutesEnum } from './../../../../../../ts/enums/routes.enum';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from './../../../../../../services/others/global.service';
import { RouterUtilService } from './../../../../../../services/utils/router-util.service';
import { AuthNgrxService } from './../../../../../../components/auth/services/auth-ngrx.service';
import { SidebarFeatureNames } from './../../../../../../components/core/ts/enums/sidebar-feature-names.enum';
import { LanguagePickerSmallComponent } from './../../../language-picker-small/language-picker-small.component';
import { UserSettingsRoutes } from 'src/app/components/OTHER_FEATURES/user-settings/ts/enums/user-settings-routes.enum';

@Component({
	selector: 'app-side-bar-footer',
	standalone: true,
	imports: [
		CommonModule,
		LanguagePickerSmallComponent
	],
	templateUrl: './side-bar-footer.component.html',
	styleUrls: ['./side-bar-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarFooterComponent {

	@Input() isUserLoggedIn: boolean | null;
	@Input() sidebarFeatureName: SidebarFeatureNames | null;

	constructor(
		private readonly globalService: GlobalService,
		private readonly sideBarService: SideBarService,
		private readonly authNgrxService: AuthNgrxService,
		private readonly translateService: TranslateService,
		private readonly routerUtilService: RouterUtilService
	) {
		if (RouterUtilService.includesRouterUrl(window.location.hash, RoutesEnum.USER_SETTINGS)) {
			this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.USER_SETTINGS);
		}
	 }

	public onOpenManual(): void {
		window.open('https://manual.abilis.net/', '_blank');
	}

	public onOpenHomepage(): void {
		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.HOME);

		this.routerUtilService.navigateToRoute();
	}

	public onLogout(): void {
		const message = this.translateService.instant('LOGIN.logoutConfirm');
		const okCallback = () => this.authNgrxService.dispatchLogoutAction();

		this.globalService.confirm(message, okCallback);
	}

	public onOpenUserSettings(): void {
		this.sideBarService.setActiveSidebarItem(SidebarFeatureNames.USER_SETTINGS);

		const route = RouterUtilService.constructRouteWithOutlet(
			RoutesEnum.USER_SETTINGS,
			UserSettingsRoutes.LANGUAGE
		);
		this.routerUtilService.navigateToRoute(route);
	}

	public get getSidebarFeatureName(): typeof SidebarFeatureNames {
		return SidebarFeatureNames;
	}
}
