import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISideBarFeature } from './../ts/models/side-bar-feature.model';
import { SidebarFeatureNames } from './../../../../../components/core/ts/enums/sidebar-feature-names.enum';
import { PermissionsSectionKey } from './../../../../../components/auth/ts/enums/permissions-section-key.enum';

@Injectable({
	providedIn: 'root'
})
export class SideBarService {

	private readonly sidebarFeatures = [
		{
			icon: 'network.svg',
			label: 'HOME.network',
			action: SidebarFeatureNames.NETWORK,
			permission: PermissionsSectionKey.NETWORK,
			location: '/#/' + SidebarFeatureNames.NETWORK
		},
		{
			icon: 'phone.svg',
			label: 'HOME.phone',
			action: SidebarFeatureNames.PHONE,
			permission: PermissionsSectionKey.PHONE,
			location: '/#/' + SidebarFeatureNames.PHONE
		},
		{
			icon: 'automation.svg',
			label: 'HOME.auto',
			action: SidebarFeatureNames.AUTOMATION,
			permission: PermissionsSectionKey.AUTO,
			location: '/#/' + SidebarFeatureNames.AUTOMATION
		},
		{
			icon: 'recorder.svg',
			label: 'HOME.video',
			action: SidebarFeatureNames.VIDEO,
			permission: PermissionsSectionKey.VIDEO,
			location: '/#/' + SidebarFeatureNames.VIDEO
		},
		{
			icon: 'tool.svg',
			label: 'HOME.tools',
			action: SidebarFeatureNames.TOOLS,
			permission: PermissionsSectionKey.TOOLS,
			location: '/#/' + SidebarFeatureNames.TOOLS
		},
		{
			icon: 'admin.svg',
			label: 'HOME.admin',
			action: SidebarFeatureNames.ADMINISTRATION,
			permission: PermissionsSectionKey.ADMIN,
			location: '/#/' + SidebarFeatureNames.ADMINISTRATION
		}
	];

	private activeSidebarItem: BehaviorSubject<SidebarFeatureNames> = new BehaviorSubject<SidebarFeatureNames>(SidebarFeatureNames.HOME);

	constructor() {
		this.initializeActiveSidebarItem();
	}

	private initializeActiveSidebarItem(): void {
		const currentUrl = window.location.hash;
		const matchingFeature = this.sidebarFeatures.find(
			feature => currentUrl.includes(feature.action)
		);

		if (matchingFeature) {
			this.activeSidebarItem.next(matchingFeature.action);
		} else if (currentUrl.includes(SidebarFeatureNames.USER_SETTINGS)) {
			this.activeSidebarItem.next(SidebarFeatureNames.USER_SETTINGS);
		}
	}

	public get getSidebarFeatures(): ISideBarFeature[] {
		return this.sidebarFeatures.slice();
	}

	public setActiveSidebarItem(sidebarFeatureName: SidebarFeatureNames): void {
		this.activeSidebarItem.next(sidebarFeatureName);
	}

	public get getActiveSidebarItem$(): Observable<SidebarFeatureNames> {
		return this.activeSidebarItem.asObservable();
	}
}
