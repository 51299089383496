import { Injectable } from '@angular/core';
import { map, tap, take, exhaustMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from './../../auth/services/auth.service';
import { switchMap, takeUntil, filter, forkJoin, of } from 'rxjs';
import { PhoneUtilService } from './../services/phone-util.service';
import { PhoneHttpService } from './../services/http/phone-http.service';
import { IPhoneCallRecord } from './../ts/models/phone-call-record.model';
import { PhoneCallsService } from './../services/calls/phone-calls.service';
import { PhoneHttpUtilService } from './../services/http/phone-http-util.service';
import { PhoneNgrxActionService } from './../services/ngrx/phone-ngrx-action.service';
import { OpcPanelCtiControl } from '../../opc-panel/ts/enums/opc-panel-cti-control.enum';
import { PhoneNgrxSelectorService } from './../services/ngrx/phone-ngrx-selector.service';
import { PreferencesHttpService } from './../../preferences/services/preferences-http.service';
import { OpcPanelCallRecordState } from '../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { PhoneNgrxEffectHandlerService } from './../services/ngrx/phone-ngrx-effect-handler.service';
import { PhoneNgrxFooterSelectorService } from './../services/ngrx/phone-ngrx-footer-selector.service';
import { OpcPanelGroupHttpService } from './../../opc-panel/services/group/opc-panel-group-http.service';
import { RecentCallHttpService } from '../../PHONE_FEATURES/recent-calls/services/http/recent-call-http.service';
import { AddressBookContactHttpService } from './../../PHONE_FEATURES/address-book/services/http/address-book-contact-http.service';
import * as customOperators from '../../../custom-operators/custom-operators';
import * as phoneOperators from '../utils/phone-operators';
import * as phoneActions from './phone-component.actions';

@Injectable()
export class PhoneComponentEffects {

	constructor(
		private readonly actions$: Actions,
		private readonly authService: AuthService,
		private readonly phoneUtilService: PhoneUtilService,
		private readonly phoneHttpService: PhoneHttpService,
		private readonly phoneCallsService: PhoneCallsService,
		private readonly phoneHttpUtilService: PhoneHttpUtilService,
		private readonly recentCallHttpService: RecentCallHttpService,
		private readonly preferencesHttpService: PreferencesHttpService,
		private readonly phoneNgrxActionService: PhoneNgrxActionService,
		private readonly phoneNgrxSelectorService: PhoneNgrxSelectorService,
		private readonly opcPanelGroupHttpService: OpcPanelGroupHttpService,
		private readonly addressBookContactHttpService: AddressBookContactHttpService,
		private readonly phoneNgrxEffectHandlerService: PhoneNgrxEffectHandlerService,
		private readonly phoneNgrxFooterSelectorService: PhoneNgrxFooterSelectorService
	) { }

	fetchPhoneStatus$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.fetchPhoneStatus),
		tap(() => {
			this.phoneNgrxActionService.fetchRecentCalls();
			this.phoneNgrxActionService.fetchSmsAvailability();
			this.phoneNgrxActionService.fetchUserPreferences();
		}),
		switchMap(() => this.opcPanelGroupHttpService.fetchInitialSessionData$()
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		phoneOperators.setPhoneActivity$(this.phoneHttpUtilService, this.phoneNgrxSelectorService),
		tap(() => this.phoneNgrxActionService.checkToUpdateCallRecords()),
		filter(() => this.opcPanelGroupHttpService.getOpcSessionId !== ''),
		map(() => phoneActions.fetchPhoneStatusSessionChanges())
	));

	fetchPhoneStatusSessionChanges$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.fetchPhoneStatusSessionChanges),
		switchMap(() => this.opcPanelGroupHttpService.fetchSessionChanges$()
			.pipe(
				customOperators.handle404Error$(
					this.opcPanelGroupHttpService.fetchInitialSessionData$(), () => {},
					this.phoneNgrxActionService.setFetchError.bind(this.phoneNgrxActionService)
				),
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		tap(() => this.phoneNgrxActionService.setFetchError(false)),
		phoneOperators.setPhoneActivity$(this.phoneHttpUtilService, this.phoneNgrxSelectorService)
	), { dispatch: false });

	handleNewCallRecords$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.handleNewCallRecords),
		switchMap(action => this.phoneNgrxSelectorService.selectCallRecordsSnapshot$()
			.pipe(
				map(existingCallRecords => this.phoneCallsService.handleNewCallRecords(existingCallRecords, action.payload.newCallRecords))
			)),
		switchMap(newCallRecords => forkJoin({
			newCallRecords: of(newCallRecords),
			callRecordsInfo: this.phoneNgrxSelectorService.selectCallRecordsInfoSnapshot$(),
			isDialpadUnavailable: this.phoneNgrxSelectorService.isDialpadUnavailable$().pipe(take(1))
		})),
		map(response => {
			const { newCallRecords, callRecordsInfo, isDialpadUnavailable } = response;

			this.phoneNgrxActionService.setCallRecordInfo(newCallRecords, callRecordsInfo);
			this.phoneNgrxActionService.setStatus(PhoneCallsService.getPhoneMenuModeToSet(isDialpadUnavailable, newCallRecords));

			return phoneActions.setCallRecords({ payload: { callRecords: newCallRecords } });
		})
	));

	checkToUpdateCallRecords$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.checkToUpdateCallRecords),
		switchMap(() => this.phoneHttpService.pollFetchCallRecords$()),
		map(callRecords => phoneActions.updateCallRecords({ payload: { callRecords } }))
	));

	fetchRecentCalls$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.fetchRecentCalls),
		filter(() => !this.phoneUtilService.isRecentCallsMode),
		switchMap(() => this.recentCallHttpService.fetchRecentCalls$(this.authService.getUsername())
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		map(recentCalls => this.phoneNgrxEffectHandlerService.setRecentCallsLog(recentCalls))
	));

	pollFetchRecentCalls$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.pollFetchRecentCalls),
		switchMap(() => this.phoneNgrxSelectorService.selectRecentCalls$().pipe(take(1))),
		filter(() => !this.phoneUtilService.isRecentCallsMode),
		exhaustMap(previousRecentCalls => this.phoneHttpService.pollFetchRecentCalls$(previousRecentCalls, this.authService.getUsername())
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		map(recentCalls => this.phoneNgrxEffectHandlerService.setRecentCallsLog(recentCalls))
	));

	sendSmsUserAction$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.sendSmsUserAction),
		switchMap(action => {
			const { phoneNumber: num, smsContent: smstext } = action.payload;

			return this.addressBookContactHttpService.sendSms$({ num, smstext })
				.pipe(
					takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
				);
		}),
	), { dispatch: false });

	fetchUserPreferences$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.fetchUserPreferences),
		switchMap(() => this.preferencesHttpService.fetchUserPreferences$(this.authService.getUsername())
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		map(userPreferences => phoneActions.setUserPreferences({ payload: { userPreferences } }))
	));

	setPhonePreferences$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.setPhonePreferences),
		switchMap(action => this.phoneHttpService.setUserPreferences$(action.payload)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		tap(() => this.phoneNgrxActionService.setUnconfiguredFooterState(false)),
		filter(response => response !== null),
		map(() => phoneActions.fetchUserPreferences())
	));

	setCtiControl$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.setCtiControl),
		switchMap(action => this.phoneHttpService.setCtiControl$(action.payload)
			.pipe(
				takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
			)),
		tap(() => this.phoneNgrxActionService.setUnconfiguredFooterState(false))
	), { dispatch: false });

	handleConferenceCall$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.handleConferenceCall),
		switchMap(action => this.phoneNgrxSelectorService.selectCallRecordsSnapshot$()
			.pipe(
				map(callRecords => this.phoneNgrxEffectHandlerService.getParameterPayloadForConferenceCall(callRecords)),
				filter(parameterPayload => !!parameterPayload),
				switchMap(Par => this.phoneHttpService.setCtiControl$({ ...action.payload, Par })
					.pipe(
						takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
					))
			))
	), { dispatch: false });

	setCallTry$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.setCallTry),
		switchMap(action => this.phoneNgrxSelectorService.selectInputValue$()
			.pipe(
				take(1),
				map(inputValue => PhoneUtilService.formatPhoneNumberForCall(inputValue)),
				switchMap(phoneNumber => this.phoneHttpService.setCtiControl$({ ...action.payload, Par: { NUM: phoneNumber } })
					.pipe(
						takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
					))
			)),
		tap(() => this.phoneNgrxActionService.setUnconfiguredFooterState(false))
	), { dispatch: false });

	setCallHoldCallRecord$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.setCallHold, phoneActions.setRecordCall),
		switchMap(action => {
			const { Cmd } = action.payload;
			const isHoldAction = action.type === '[Phone] Set Call Hold';
			const callState = this.phoneNgrxEffectHandlerService.getHoldRecordCallState(isHoldAction, Cmd as OpcPanelCallRecordState);

			return forkJoin({
				payload: of(action.payload),
				isCallHold: of(isHoldAction),
				callRecord: this.phoneNgrxSelectorService.selectCallRecordsSnapshot$()
					.pipe(
						map(callRecords => PhoneCallsService.getCallRecordByState(callRecords, callState))
					)
			});
		}),
		filter(response => {
			const { callRecord, isCallHold } = response;

			return this.phoneNgrxEffectHandlerService.handleSetFilterOnCallHoldRecord(callRecord, isCallHold);
		}),
		switchMap(response => {
			const { payload, callRecord, isCallHold } = response;
			const Par = this.phoneNgrxEffectHandlerService.getParameterPayloadForCallHoldRecord(callRecord as IPhoneCallRecord, isCallHold);

			return this.phoneHttpService.setCtiControl$({ ...payload, Par })
				.pipe(
					takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables))),
					tap(() => this.phoneNgrxActionService.setUnconfiguredFooterState(false))
				);
		})
	), { dispatch: false });

	callUser$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.callUser),
		switchMap(action => forkJoin({
			actionPayload: of(action.payload),
			priorityMode: this.phoneNgrxFooterSelectorService.selectPriorityMode$().pipe(take(1))
		})),
		switchMap(response => {
			const { actionPayload, priorityMode } = response;
			const { num, prefix, action } = actionPayload;
			const payload = this.phoneUtilService.getCallPayload(priorityMode, num, prefix, action);

			if (priorityMode) {
				this.phoneNgrxActionService.setPriorityMode(false);
			}

			return this.phoneHttpService.setCtiControl$(payload)
				.pipe(
					takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
				);
		})
	), { dispatch: false });

	abortCall$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.abortCall),
		map(() => this.phoneUtilService.getCtiActionPayload(OpcPanelCtiControl.CLOSE)),
		switchMap(payload => this.phoneHttpService.setCtiControl$({ ...payload }))
	), { dispatch: false });

	sendDtmf$ = createEffect(() => this.actions$.pipe(
		ofType(phoneActions.sendDtmf),
		switchMap(action => forkJoin({
			actionPayload: of(action.payload)
		})),
		switchMap(response => {
			const { actionPayload } = response;
			const { digit, duration, action } = actionPayload;
			const payload = this.phoneUtilService.getDtmfPayload(digit, duration, action);

			return this.phoneHttpService.setCtiControl$(payload)
				.pipe(
					takeUntil(this.actions$.pipe(ofType(phoneActions.cancelPhoneHTTPObservables)))
				);
		})
	), { dispatch: false });

}
