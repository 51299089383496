import { Injectable } from '@angular/core';
import { PhoneMode } from '../ts/enums/phone-mode.enum';
import { PhoneCallsService } from './calls/phone-calls.service';
import { AuthService } from './../../auth/services/auth.service';
import { PhoneHttpUtilService } from './http/phone-http-util.service';
import { IPhoneCallRecord } from '../ts/models/phone-call-record.model';
import { IPhoneModePayload } from '../ts/models/phone-mode-payload.model';
import { PhoneFooterUtilService } from './footer/phone-footer-util.service';
import { OpcPanelInterface } from './../../opc-panel/ts/enums/opc-panel-interface.enum';
import { OpcPanelCtiControl } from '../../opc-panel/ts/enums/opc-panel-cti-control.enum';
import { OpcPanelCallDirection } from './../../opc-panel/ts/enums/opc-panel-call-direction.enum';
import { PreferencesInterfaceType } from './../../preferences/ts/types/preferences-interface.type';
import { OpcPanelUserRecordState } from '../../opc-panel/ts/enums/opc-panel-user-record-state.enum';
import { IRecentCall } from './../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { IOpcPanelUserRecord } from '../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { OpcPanelCallRecordState } from './../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { IPreferencesInterfaceType } from './../../preferences/ts/enums/preferences-interface-type.enum';
import { PreferencesInterfaceAction } from '../../preferences/ts/enums/preferences-interface-action.enum';
import { RecentCallDirection } from './../../PHONE_FEATURES/recent-calls/ts/enums/recent-call-direction.enum';
import { RecentCallTableService } from '../../PHONE_FEATURES/recent-calls/services/table/recent-call-table.service';
import { RecentCallNgrxActionService } from '../../PHONE_FEATURES/recent-calls/services/ngrx/recent-call-ngrx-action.service';
import { AddressBookTableUtilService } from '../../PHONE_FEATURES/address-book/services/table/address-book-table-util.service';
import { AddressBookNgrxActionService } from '../../PHONE_FEATURES/address-book/services/ngrx/address-book-ngrx-action.service';

@Injectable({
	providedIn: 'root'
})
export class PhoneUtilService {

	private mode: PhoneMode;

	constructor(
		private readonly authService: AuthService,
		private readonly recentCallTableService: RecentCallTableService,
		private readonly recentCallNgrxActionService: RecentCallNgrxActionService,
		private readonly addressBookNgrxActionService: AddressBookNgrxActionService
	) { }

	public setPhoneMode(mode: PhoneMode): void {
		this.mode = mode;
	}

	public get getPhoneMode(): PhoneMode {
		return this.mode;
	}

	public static formatPhoneNumberForCall(value: string, prefix: string = ''): string {
		const phoneNumber = this.removePrefixFromPhoneNumber(value, prefix);

		if (!phoneNumber.endsWith('#')) { return `${phoneNumber}#`; }

		return `${phoneNumber.replace(/#{1,}$/, '')}#`;
	}

	public static removePrefixFromPhoneNumber(phoneNumber: string, prefix: string): string {
		return !prefix ? phoneNumber : phoneNumber.slice(prefix.length);
	}

	public static isNullCallRecord(state: OpcPanelCallRecordState): boolean {
		return state === OpcPanelCallRecordState.NULL;
	}

	public static isInDirection(direction: OpcPanelCallDirection | RecentCallDirection): boolean {
		return direction === OpcPanelCallDirection.IN;
	}

	public static isCtipInterface(phoneInterface: OpcPanelInterface): boolean {
		return phoneInterface === OpcPanelInterface.CTIP;
	}

	public static isSomeCallIdMatching(callRecords: IPhoneCallRecord[], callId: number): boolean {
		return callRecords.some(callRecord => callRecord.CallID === callId);
	}

	public static getPhoneNumberFromRecentCall(recentCallItem: IRecentCall): string {
		const { called, calling, isInDirection } = recentCallItem;

		return isInDirection ? calling : called;
	}

	public static areRecentCallsTheSame(previousRecentCalls: IRecentCall[], callRecords: IRecentCall[]): boolean {
		return callRecords.length === previousRecentCalls.length;
	}

	public static isCommandExecuted(lowercaseResponseText: string): boolean {
		return ['command executed', 'ok'].some(value => lowercaseResponseText.includes(value));
	}

	public static isMatchingAction(
		action: PreferencesInterfaceAction | undefined, actionToMatch: PreferencesInterfaceAction
	): boolean | null {
		return action === undefined ? null : action === actionToMatch;
	}

	public static isInputEmpty(inputValue: string): boolean {
		return !inputValue.trim();
	}

	public getCallAction(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): OpcPanelCtiControl {
		return PhoneFooterUtilService.canAcceptCall(callRecords, userRecord) ? OpcPanelCtiControl.ACCEPT : OpcPanelCtiControl.CALL;
	}

	public getCallPayload(priorityMode: boolean, num: string, prefix: string, action: OpcPanelCtiControl): IPhoneModePayload {
		const precedencePayload = priorityMode ? { PRECEDENCE: 'YES' } : {};
		const callPayload = { NUM: num, PREFIX: prefix, ...precedencePayload };
		const finalPayload = action === OpcPanelCtiControl.CALL ? { Par: callPayload } : { Par: precedencePayload };

		return { ...this.getCtiActionPayload(action), ...finalPayload };
	}

	public getDtmfPayload(dtmfDigit: string, duration: number, action: OpcPanelCtiControl): IPhoneModePayload {
		const dtmfPayload = { DIGIT: dtmfDigit, DURATION: duration };
		const finalPayload = { Par: dtmfPayload };

		return { ...this.getCtiActionPayload(action), ...finalPayload };
	}

	public getCtiActionPayload(command: OpcPanelCtiControl): IPhoneModePayload {
		const user = this.authService.getUsername();
		const abilis = PhoneHttpUtilService.getUserInfo.A;

		return { A: abilis, U: user, I: 'CTIP', Cmd: command };
	}

	public get getPhoneNumberRegExp(): RegExp {
		return /^\+?[0-9#*]+$/;
	}

	public isInputMatchingKeypadRegexp(value: string): boolean {
		return this.getPhoneNumberRegExp.test(value);
	}

	public getCtipInterface(interfaces: PreferencesInterfaceType[]): PreferencesInterfaceType | null {
		return interfaces.find(item => item.type === IPreferencesInterfaceType.CTIP) || null;
	}

	public allowLongpressDial(callRecords: IPhoneCallRecord[], recordNumber: string): boolean {
		if (!callRecords.length) { return true; }

		const activeCall = PhoneCallsService.getCallRecordByState(callRecords, OpcPanelCallRecordState.ACTIVE);

		if (!activeCall) { return false; }

		return PhoneCallsService.getCallingNumber(activeCall) !== recordNumber;
	}

	public shouldPresentCallEndIcon(callRecords: IPhoneCallRecord[], userRecord: IOpcPanelUserRecord | null): boolean {
		const activeCallStates = [
			OpcPanelCallRecordState.HOLD, OpcPanelCallRecordState.ACTIVE,
			OpcPanelCallRecordState.CALLING, OpcPanelCallRecordState.ALERTING
		];

		return this.isUserRecordRinging(userRecord) || PhoneCallsService.someCallStateMatches(callRecords, activeCallStates);
	}

	private isUserRecordRinging(userRecord: IOpcPanelUserRecord | null): boolean {
		return !userRecord ? false : userRecord.State === OpcPanelUserRecordState.RINGING;
	}

	public handleUncheckTableRows(): void {
		if (this.isRecentCallsMode) {
			this.recentCallTableService.setUnselectedRowState(true);
			this.recentCallNgrxActionService.uncheckAllRecentCalls();
			return;
		}

		AddressBookTableUtilService.setUnselectedRowState(true);
		this.addressBookNgrxActionService.setCheckedContactsState(false);
	}

	public get isRecentCallsMode(): boolean {
		return this.mode === PhoneMode.RECENT_CALLS;
	}

	public get isAddressBookMode(): boolean {
		return this.mode === PhoneMode.ADDRESS_BOOK;
	}
}
