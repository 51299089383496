import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { PhoneUtilService } from './../phone-util.service';
import { IAppState } from './../../../../root-reducers/index';
import { PhoneCallsService } from './../calls/phone-calls.service';
import { IPhoneComponentState } from './../../ngrx/phone-component.reducer';
import { IPhoneCallRecord } from './../../ts/models/phone-call-record.model';
import { PhoneFooterUtilService } from '../footer/phone-footer-util.service';
import { Observable, of, combineLatest, filter, switchMap, forkJoin } from 'rxjs';
import { IPhoneCallRecordInfo } from '../../ts/models/phone-call-record-info.model';
import { IPreferencesStore } from './../../../preferences/ts/models/preferences-store.model';
import { PreferencesInterfaceType } from './../../../preferences/ts/types/preferences-interface.type';
import { IRecentCall } from './../../../PHONE_FEATURES/recent-calls/ts/models/recent-call-table.model';
import { OpcPanelUserRecordState } from '../../../opc-panel/ts/enums/opc-panel-user-record-state.enum';
import { IOpcPanelUserRecord } from './../../../opc-panel/ts/models/session/opc-panel-user-record.model';
import { OpcPanelCallRecordState } from './../../../opc-panel/ts/enums/opc-panel-call-record-state.enum';
import { AddressBookNgrxSelectorService } from './../../../PHONE_FEATURES/address-book/services/ngrx/address-book-ngrx-selector.service';
import * as phoneSelectors from '../../ngrx/phone-component.selectors';

@Injectable({
	providedIn: 'root'
})
export class PhoneNgrxSelectorService {

	constructor(
		private readonly store: Store<IAppState>,
		private readonly phoneUtilService: PhoneUtilService,
		private readonly addressBookNgrxSelectorService: AddressBookNgrxSelectorService
	) { }

	public selectFromPhoneStore$(selector: keyof IPhoneComponentState): Observable<IPhoneComponentState[keyof IPhoneComponentState]> {
		return this.store.select(phoneSelectors.selectFromPhoneStore(selector));
	}

	public selectInputValue$(): Observable<string> {
		return this.selectFromPhoneStore$('input') as Observable<string>;
	}

	public shouldClearInput$(): Observable<boolean> {
		return this.selectFromPhoneStore$('clearNextInput') as Observable<boolean>;
	}

	public selectUnconfiguredFooterState$(): Observable<boolean> {
		return this.selectFromPhoneStore$('unconfiguredFooter') as Observable<boolean>;
	}

	public selectUserRecord$(): Observable<IOpcPanelUserRecord | null> {
		return this.selectFromPhoneStore$('userRecord') as Observable<IOpcPanelUserRecord | null>;
	}

	public selectCallRecordsInfo$(): Observable<IPhoneCallRecordInfo[]> {
		return (this.selectFromPhoneStore$('callRecordsInfo') as Observable<IPhoneCallRecordInfo[]>);
	}

	public selectSmsNumber$(): Observable<string> {
		const inputValue$ = this.selectInputValue$().pipe(take(1));

		if (!this.phoneUtilService.isAddressBookMode) { return inputValue$; }

		return this.addressBookNgrxSelectorService.getSelectedContact$()
			.pipe(
				switchMap(selectedContact => forkJoin({
					inputValue: inputValue$,
					selectedContact: of(selectedContact)
				})),
				map(response => {
					const { selectedContact, inputValue } = response;

					return selectedContact ? selectedContact.num : inputValue;
				})
			);
	}

	public isDialpadUnavailable$(): Observable<boolean> {
		return this.selectUserRecord$()
			.pipe(
				map(userRecord => !userRecord || userRecord?.State === OpcPanelUserRecordState.NOTREADY)
			);
	}

	public selectCallRecordsInfoSnapshot$(): Observable<IPhoneCallRecordInfo[]> {
		return this.selectCallRecordsInfo$()
			.pipe(
				take(1)
			);
	}

	public selectDialText$(): Observable<string> {
		return this.selectCallRecords$()
			.pipe(
				map(callRecords => {
					const someActiveCall = PhoneCallsService.someCallStateMatches(callRecords, [OpcPanelCallRecordState.ACTIVE]);

					return !someActiveCall ? 'PHONE-COMP.dialing' : 'PHONE-COMP.switching';
				})
			);
	}

	public selectRecentCalls$(): Observable<IRecentCall[]> {
		if (this.phoneUtilService.isRecentCallsMode) { return of([]); }

		return this.selectFromPhoneStore$('recentCalls') as Observable<IRecentCall[]>;
	}

	public selectCallRecords$(): Observable<IPhoneCallRecord[]> {
		return this.store.pipe(select(phoneSelectors.selectAllCallRecords()));
	}

	public selectCallRecordsSnapshot$(): Observable<IPhoneCallRecord[]> {
		return this.selectCallRecords$()
			.pipe(
				take(1)
			);
	}

	public allowToPerformCall$(): Observable<boolean> {
		return this.selectInputValue$()
			.pipe(
				map(value => !!value && this.phoneUtilService.isInputMatchingKeypadRegexp(value))
			);
	}

	public isCallIconActive$(): Observable<boolean> {
		return combineLatest({
			userRecord: this.selectUserRecord$(),
			callRecords: this.selectCallRecords$(),
			allowToPerformCall: this.allowToPerformCall$()
		})
			.pipe(
				filter(response => response.allowToPerformCall),
				map(response => {
					const { callRecords, userRecord } = response;

					const canCall = PhoneFooterUtilService.canCall(callRecords, userRecord);
					const canAccept = PhoneFooterUtilService.canAcceptCall(callRecords, userRecord);
					const inactiveStates = [OpcPanelCallRecordState.ACTIVE, OpcPanelCallRecordState.DISCONNECTING];
					const isSomeActiveDisconnecting = PhoneCallsService.someCallStateMatches(callRecords, inactiveStates);

					return (canCall || canAccept) && !isSomeActiveDisconnecting;
				})
			);
	}

	public isEndCallIconActive$(): Observable<boolean> {
		return combineLatest({
			userRecord: this.selectUserRecord$(),
			callRecords: this.selectCallRecords$()
		})
			.pipe(
				map(response => {
					const { userRecord, callRecords } = response;

					return this.phoneUtilService.shouldPresentCallEndIcon(callRecords, userRecord);
				})
			);
	}

	public selectCtipInterface$(): Observable<PreferencesInterfaceType | null> {
		return (this.selectFromPhoneStore$('userPreferences') as Observable<null | IPreferencesStore>)
			.pipe(
				map(userPreferences => this.phoneUtilService.getCtipInterface(userPreferences?.interfaces || []))
			);
	}

	public isVpots$(): Observable<boolean> {
		return this.selectUserRecord$()
			.pipe(
				map(userRecord => PhoneFooterUtilService.isVpots(userRecord, true))
			);
	}

	public isWpots$(): Observable<boolean> {
		return this.selectUserRecord$()
			.pipe(
				map(userRecord => PhoneFooterUtilService.isWpots(userRecord, true))
			);
	}

}
